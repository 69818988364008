<template>
    <div>
  <div class="footer bg-gray">
     <img class="decoration-circles" src="assets/images/decoration-circles.png" alt="alternative" />
     <div class="container">
         <div class="row">
             <div class="col-lg-12">
                 <h4>If you want to find out the best rates in the market, reach us today. </h4>
                 <div class="social-container">
                     <span class="fa-stack">
                         <a href="#">
                             <i class="fas fa-circle fa-stack-2x"></i>
                             <i class="fab fa-facebook-f fa-stack-1x"></i>
                         </a>
                     </span>
                     <span class="fa-stack">
                         <a href="#">
                             <i class="fas fa-circle fa-stack-2x"></i>
                             <i class="fab fa-twitter fa-stack-1x"></i>
                         </a>
                     </span>
                     <span class="fa-stack">
                         <a href="#">
                             <i class="fas fa-circle fa-stack-2x"></i>
                             <i class="fab fa-pinterest-p fa-stack-1x"></i>
                         </a>
                     </span>
                     <span class="fa-stack">
                         <a href="#">
                             <i class="fas fa-circle fa-stack-2x"></i>
                             <i class="fab fa-instagram fa-stack-1x"></i>
                         </a>
                     </span>
                     <span class="fa-stack">
                         <a href="#">
                             <i class="fas fa-circle fa-stack-2x"></i>
                             <i class="fab fa-youtube fa-stack-1x"></i>
                         </a>
                     </span>
                 </div> 
             </div> 
         </div>
     </div> 
   </div> 
   <div class="copyright bg-gray">
     <div class="container">
         <div class="row">
             <div class="col-lg-6 col-md-12 col-sm-12">
                 <ul class="list-unstyled li-space-lg p-small">
                     <!-- <li><a href="#">Article Details</a></li> -->
                     <li><a href="#">Terms & Conditions</a></li>
                     <li><a href="#">Privacy Policy</a></li>
                 </ul>
             </div> 
             <div class="col-lg-3 col-md-12 col-sm-12">
                 <p class="p-small statement">Copyright © <a href="#">remmi Center</a></p>
             </div> 
           
         </div> 
     </div> 
   </div>
</div>
    <!-- <footer>
      <p>&copy; 2024 DurraFx. All rights reserved.</p>
    </footer> -->
  </template>
  
  <script>
  export default {
    name: 'Footer'
  }
  </script>
  
  <style scoped>
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  </style>
  