import store from './store/index.js'
import { createRouter, createWebHistory } from "vue-router";

// import HomePage from "./components/pages/durraHome";
import HomePage from "./components/pages/NewHome.vue";


import Login from "./components/pages/Login";
import DashboardPage from "./components/pages/DashboardPage.vue";
import registerClient from "./components/pages/registerClient.vue";
import allClients from "./components/pages/allClients.vue";
import loans from "./components/pages/loans.vue";

import userRegistration from "./components/pages/RegisterUser.vue";
import newLoan from "./components/pages/newLoan.vue";



// import allTrades from "./components/pages/allTrades.vue"
















const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    // { path: "/about", component: aboutPage },
  
   
    { path: "/user/registration/", component: userRegistration },
    { path: "/login/", component: Login },
    { path: "/dashboard/", component: DashboardPage, meta: {
      requiresAuth: true
    } },
    { path: "/register/client", component: registerClient, meta: {
      requiresAuth: true
    } },
    { path: "/clients", component: allClients, meta: {
      requiresAuth: true
    } },
    { path: "/loans", component: loans, meta: {
      requiresAuth: true
    } },
    { path: "/loan/id/:id_number", component: newLoan, meta: {
      requiresAuth: true
    } },
   
    


    // {path: "/dashboard", component:DashBoard, meta: {
    //   requiresAuth: true
    // }},



    
   
    

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
   
    if (!store.getters.isLoggedIn) {
      next({ path: '/login' })
    } else {
      next() 
    }
  } else {
    next() 
  }
})

export default router;
