<template>
    <div>
     <base-navigation></base-navigation>
      
     <base-card> 
    <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="client name"  aria-label="Search"  />
</div>
</base-card>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <th><small> Client name</small></th>
                <th><small>Id Number</small></th>
                <th><small>Business Name</small></th>
                <th><small>Action</small></th>
               
              
            </tr>
            </thead> 
            <tbody>
                <tr v-for="client in filteredClients.slice(0,4)" :key="client.id">
          <td> <router-link
            :to="`/loan/id/${client.id_number}`"
          >
            {{ client.name }}
          </router-link> </td> 
          
     
                   
                  
                    <td><small>{{ client.id_number  }}</small></td>
                    <td><small>{{ client.Business_name  }}</small></td>
                    <td> <router-link
            :to="`/loan/id/${client.id_number}`"
          >
           Issue Loan
          </router-link> </td> 

                   
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

</div>
</template>
<script>


export default {
    data() {
    return {
      search: "",
    };
  },
    computed: {
   
    clients() {
            return  this.$store.getters.clients //.filter(process=>process.processed =='0')

            
        },
        filteredClients() {
        return this.clients.filter((client)=>client.name.toLowerCase().includes(this.search.toLowerCase())| client.id_number.toString().includes(this.search.toLowerCase())).slice(0,5)
        //return this.students.filter((student)=>student.student_name.toLowerCase().includes(this.search.toLowerCase()))).slice(0,5)
        
       
    },
  

    },
    actions: {
     
    
    


    },
     created() {
    this.$store.dispatch('LoadClients');  
    
    
  },

}

</script>