<template>
    <div>
     <base-navigation></base-navigation>
      
     <base-card> 
    <!-- <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="client name"  aria-label="Search"  />
</div> -->
</base-card>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <th><small> Client name</small></th>
                <th><small> Id Number</small></th>
                <th><small>Loan Amount</small></th>
                <th><small>Durationt</small></th>
                <th><small>Issue date</small></th>
                <th><small>Interest rate</small></th>
                <th><small>Return Date</small></th>
                <th><small>Repayment</small></th>
                <th><small>Amt Repaid</small></th>

                <th><small>status</small></th>
               
              
            </tr>
            </thead> 
            <tbody>
                <tr v-for="loan in loans" :key="loan.id">
          <!-- <td> <router-link
            :to="`/loan/id/${client.id_number}`"
          >
            {{ client.name }}
          </router-link> </td> 
           -->
     
                   
                  
                    <td><small>{{ loan.name  }}</small></td>
                    <td><small>{{ loan.id_number  }}</small></td>
                    <td><small>{{ loan.amount  }}</small></td>
                    <td><small>{{ loan.duration  }}</small></td>

                    

                    
                    <td><small>{{ loan.Date_of_issue.split("T")[0]  }}</small></td>
                    <td><small>{{ loan.interest_rate }}%</small></td>
                    <td><small>{{ loan.return_date.split("T")[0]  }}</small></td>
                    <td><small>{{ loan.repayment_amount }}</small></td>
                    <td><small>{{ loan.amount_repaid }}</small></td>


                    <td><small>{{ loan.status }}</small></td>
                    <!-- <td> <router-link
            :to="`/loan/id/${loan.id_number}`"
          >
           Issue Loan
          </router-link> </td>  -->

                   
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

</div>
</template>
<script>


export default {
    data() {
    return {
      search: "",
    };
  },
    computed: {
   
    loans() {
            return  this.$store.getters.loans //.filter(process=>process.processed =='0')

            
        },
        filteredClients() {
        return this.clients.filter((client)=>client.name.toLowerCase().includes(this.search.toLowerCase())| client.id_number.toString().includes(this.search.toLowerCase())).slice(0,5)
        //return this.students.filter((student)=>student.student_name.toLowerCase().includes(this.search.toLowerCase()))).slice(0,5)
        
       
    },
  

    },
    actions: {
     
    
    


    },
     created() {
    this.$store.dispatch('LoadLoans');  
    
    
  },

}

</script>