<template>
  <div class="partners-section">
    <h2>Our Partners</h2>
    <div class="partners-scroll">
      <div class="partners-track">
        <div class="partner" v-for="(partner, index) in duplicatedPartners" :key="index">
          <img :src="partner.image" :alt="partner.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partners: [
      { name: 'Partner 1', image: 'assets/images/roberms.jpg' },
      { name: 'Partner 1', image: 'assets/images/yc.jpg' },
      { name: 'Partner 1', image: 'assets/images/DTB.jpg' },
      { name: 'Partner 1', image: 'assets/images/equity.jpg' },
      { name: 'Partner 1', image: 'assets/images/global.jpg' },
      // { name: 'Partner 1', image: 'assets/images/roberms.jpg' },
      ]
    };
  },
  computed: {
    duplicatedPartners() {
      // Duplicate the partners array to create a seamless loop
      return [...this.partners, ...this.partners];
    }
  }
}
</script>

<style scoped>
.partners-section {
  text-align: center;
  padding: 20px;
  overflow: hidden;
}

.partners-scroll {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.partners-track {
  display: inline-block;
  animation: scroll 20s linear infinite;
}

.partner {
  display: inline-block;
  margin: 0 10px;
}

.partner img {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
