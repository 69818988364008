<template>
    <div>
    <main-navigation></main-navigation>
    <header id="header" class="">
      <div class="main-content">
        <carousel-section></carousel-section>
       
      </div> 
    </header> 
    
    
    <div id="partiners">

     
        <!-- Our partiners -->

        <!-- <partiner-section></partiner-section> -->
    </div>
    <div class="basic-2">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-xl-5">
                  <div class="text-container">
                      <div class="section-title">ABOUT US</div>
                      <h2>We have 5 in disbursing loans</h2>
                      <p>Salary advance</p>
                      <p>Logbook loans</p>
                      <p>Emergency loans</p>
                      <p>Loans on items</p>
                      <a class="btn-outline-reg" href="#">Details</a>
                  </div> 
              </div> 
              <div class="col-lg-6 col-xl-7">
                  <div class="image-container">
                      <img class="img-fluid" src="assets/images/details-2.png" alt="alternative" />
                  </div> 
              </div> 
          </div>
      </div> 
    </div> 
   
    
    <div id="leadership" class="container">
 
    
    <br>
    <br>
    <br>
    
    <br>
       
     </div>

   
    
    
    <div id="contact" class="form-1">
      <div class="container">
          <div class="row">
              <div class="col-lg-6">
                  <div class="text-container">
                      <div class="section-title">Interested in our loans?</div>
                      <h2>Fill the form below we get back to you</h2>
                      <p></p>
                    
                  </div> 
              </div> 
              <div class="col-lg-6">
                 
                  <form>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Name" required />
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="phone" required />
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Location" required />
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Id number" required />
                      </div>
                      <div class="form-group">
                          <button type="submit" class="form-control-submit-button">Submit</button>
                      </div>
                  </form>
                
              </div> 
          </div>
      </div> 
    </div>
    <footer-bar></footer-bar>
    
    <button id="myBtn">
      <img src="assets/images/up-arrow.png" alt="alternative" />
    </button>
    </div>
    </template>
    
    
    
    <style>
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 0px;
    }
    .main-content {
  padding-top: -40px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
}
    </style>
    