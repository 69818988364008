<template>
    <div>
    <base-navigation></base-navigation>
    <base-card>
    <div class="loan-form">
      <h4>Loan Application for  Id number <b class="text-secondary">{{ memberids }}</b> </h4>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}
        <button @click="goBackToLoans">Cancel</button>
      </div>
      
      <!-- is previosu loan cleared if given? {{ loan_cleared }} -->
      <form @submit.prevent="submitData">
        <div class="row">
            <div class="col">
                <div class="form-group">
          <label for="loanAmount">Loan Amount:</label>
          <input type="number" v-model="formData.amount" id="loanAmount" required />
        </div>

       
  
        <div class="form-group">
          <label for="interestRate">Interest Rate (%):</label>
          <input type="float" v-model="formData.interest_rate" id="interestRate" required />
        </div>

        <div class="form-group">
          <label for="perid">Period:</label>
          <select v-model="formData.duration" id="period" required>
            <option disabled value="">Please select period</option>
            <option>1 day</option>
            <option>7 days (1 week)</option>
            <option>14 days (2 weeks)</option>
            <option>30 days (1 Month)</option>

          </select>
        </div>
  
       
            </div>
            <div class="col">
            
                <div class="form-group">
          <label for="duration">Guarantor Name:</label>
          <input type="text" v-model="formData.gName" id="cname" required />
        </div>
        <div class="form-group">
          <label for="duration">Guarantor Id number:</label>
          <input type="number" v-model="formData.gId" id="gid" required />
        </div>
        <div class="form-group">
          <label for="duration">Guarantor phone:</label>
          <input type="text" v-model="formData.gPhone" id="gphone" required />
        </div>
        <div class="form-group">
          <label for="duration">Guarantor Relationship:</label>
          <select v-model="formData.guarantor_relationship" id="period" required>
            <option disabled value="">Please select relationship</option>
            <option>Parent</option>
            <option>Sibling</option>
            <option>Business Partiner</option>
            <option>Spouse</option>
            <option>Kid</option>

          </select>
        </div>


            </div>

            <div class="col">
            
            <div class="form-group">
      <label for="cname">Collateral Name:</label>
      <input type="text" v-model="formData.collateral_name " id="cname" required />
    </div>
    <div class="form-group">
      <label for="cseria">Collateral serial Number:</label>
      <input type="text" v-model="formData.collateralSerial" id="cseria" required />
    </div>
    <div class="form-group">
      <label for="camount">Collateral Amount:</label>
      <input type="text" v-model="formData.collateral_amount" id="camount" required />
    </div>
    <div class="form-group">
      <label for="camount">Collateral Owner:</label>
      <input type="text" v-model="formData.collateralOwner" id="camount" required />
    </div>


        </div>

        </div>
        <!-- <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" v-model="name" id="name" required />
        </div> -->
<!--   
        <div class="form-group">
          <label for="idNumber">ID Number:</label>
          <input type="text" v-model="idNumber" id="idNumber" required />
        </div> -->
  
        
  
        <base-button type="submit">Submit </base-button>
      </form>
    </div>
</base-card>
   <!-- Display error message if it exists -->
 
</div>

  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        memberids: this.$route.params.id_number,
        formData: {
        name: '',
        id_number: this.$route.params.id_number,
        amount: '',
        interest_rate: '',
        duration: '',
        gName: '',
        gId: '',
        guarantor_relationship:'',
        gPhone: '',
        collateral_name:'',
        collateralSerial:'',
        collateral_amount:'',
        collateralOwner:''
         
         
        },

        errorMessage: '',  // Variable to store error message
       
      };
    },
    methods: {

      async submitData() {

        const formIsValid =
        this.formData.amount &&
        this.formData.interest_rate 

        if (formIsValid)
      {
        try {
          const response = await axios.post(
          "https://api.roberms.com/remmi/loan/registration",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/loans");
        
      } catch (error) {
        // Capture the error message from the backend
        if (error.response && error.response.data && error.response.data.error) {
          this.errorMessage = error.response.data.error;
        } else {
          this.errorMessage = 'An unknown error occurred.';
        }
      }


      }
      else {
        this.errorMessage = [];
        if (!this.formData.amount) {
          this.errorMessage.push("Enter loan amount");
        }
        if (!this.formData.interest_rate) {
          this.errorMessage.push("Enter interest rate");
        }
        
        

        console.log("form is invalid and the client might have a loan that has not been fully repaid");
      }

  
    },

    goBackToLoans() {
      // Redirect to the /loans route
      this.$router.push('/loans');
    },
  



        async requestLoan() {
      const formIsValid =
        this.formData.amount &&
        this.formData.interest_rate 

        // const hasLoan = this.loan_cleared



        // const has_loan = this.getters.hasloan
        
      if (formIsValid) {

        



        const response = await axios.post(
          "https://api.roberms.com/remmi/loan/registration",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/loans");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.amount) {
          this.errors.push("Enter loan amount");
        }
        if (!this.formData.interest_rate) {
          this.errors.push("Enter interest rate");
        }
        
        

        console.log("form is invalid and the client might have a loan that has not been fully repaid");
      }
    },



      // async submitLoanForm() {

      //   const formData = new FormData();
      //   formData.append('name', this.name);
      //   formData.append('idNumber', this.idNumber);
      //   formData.append('loanAmount', this.loanAmount);
      //   formData.append('interestRate', this.interestRate);
      //   formData.append('duration', this.duration);
  
      //   try {
      //     const response = await axios.post('  "https://api.roberms.com/remmi/loan/application",', formData, {
      //       headers: {
      //         'Content-Type': 'multipart/form-data'
      //       }
      //     });
  
      //     alert(`Loan application successful! Amount payable: ${response.data.amountPayable}`);
      //   } catch (error) {
      //     console.error(error);
      //     alert('Failed to submit loan application.');
      //   }
      // }
    },
    computed: {
        memberidz() {
      return this.$router.params.id_number;
    },
    loan_cleared()
    { 
      return this.getters.isLoanCleared
    }
    },
    created() {
    this.$store.dispatch('LoadLoans');  
    
    
  },
  };
  </script>
  
  <style scoped>
  .error-message {
  color: red;
  margin-top: 10px;
}
  .loan-form {
    margin: 0 auto;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  