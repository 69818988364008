import { createStore } from "vuex";

const store = createStore({
  state: {
    token: null,
    user: null,

    
    clients: [],
    
    loans: [],
    

    
  },
  mutations: {
    clearToken(context) {
      context.commit("removeToken");
    },
    clearUser(context) {
      context.commit("removeUser");
    },
    addToken(context) {
      context.commit("setToken");
    },

    
    setClients(state, clients){
      state.clients=clients
    },
    setLoans(state, loans){
      state.loans=loans
    }
    
    


   
  },
  actions: {
    clearToken(context) {
      context.commit("removeToken");
    },
    clearUser(context) {
      context.commit("removeUser");
    },
    addToken(context) {
      context.commit("setToken");
    },

    
    async LoadClients(context) {
      const url = `https://api.roberms.com/remmi/get/clients`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const clients = [];
      for (const key in responseData) {
        const client = {
          
          id: key,
          name: responseData[key].name,
          id_number: responseData[key].id_number,

          Date_of_birth: responseData[key].Date_of_birth,
          mstatus: responseData[key].mstatus,
          gender: responseData[key].gender,
          address: responseData[key].address,
          Business_name: responseData[key].Business_name,
          status: responseData[key].status,
          Registration_date: responseData[key].Registration_date,
         

          
          processed: responseData[key].processed,
        };
        clients.push(client);
      }
      context.commit("setClients", clients);
    },

    async LoadLoans(context) {
      const url = `https://api.roberms.com/remmi/get/loans`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const loans = [];
      for (const key in responseData) {
        const loan = {
        
          id: key,
          base_currency: responseData[key].base_currency,
          counter_currency: responseData[key].counter_currency,

          name: responseData[key].name,
          id_number: responseData[key].id_number,
          amount: responseData[key].amount,
          amount_repaid: responseData[key].amount_repaid,

          repayment_amount: responseData[key].repayment_amount,

          interest_rate: responseData[key].interest_rate,
          duration: responseData[key].duration,
          guarantor_name: responseData[key].guarantor_name,
          guarantor_id: responseData[key].guarantor_id,
          guarantor_relationship: responseData[key].guarantor_relationship,
          collateralSerial: responseData[key].collateralSerial,
          collateral_name: responseData[key].collateral_name,
          collateralOwner: responseData[key].collateralOwner,
          guarantor_phone: responseData[key].guarantor_phone,
          collateral_amount: responseData[key].collateral_amount,
          return_date: responseData[key].return_date,
          Date_of_issue: responseData[key].Date_of_issue,

         
          
          status: responseData[key].status,
        };
        loans.push(loan);
      }
      context.commit("setLoans", loans);
    },



    

    
  
  
  
  

   
  
  },
  getters: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

   
    
    clients: (state) => state.clients,
    loans: (state) => state.loans,
    
    

    has_loan: (state)=>(id)=>state.loans.filter(number=>number.id_number==id),

    isLoanCleared: (state) => () => {
      const loan = state.loans.find(loan => loan.id_number === 	
        '254467890');
      return loan && loan.status === 'cleared';
    },

  },


  //
});
export default store;