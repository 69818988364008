<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/"> Remmi Loans</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li v-if="isLoggedIn" class="nav-item">
          <a class="nav-link" href="/register/client">Register client </a>
        </li>
        <!-- <li v-if="isLoggedIn" class="nav-item">
          <a class="nav-link" href="/register/client">New Loan </a>
        </li> -->
        
        <li v-if="isLoggedIn" class="nav-item">
          <a class="nav-link" href="/clients">clients</a>
        </li>
        <li v-if="isLoggedIn" class="nav-item">
          <a class="nav-link" href="/loans">Loans</a>
        </li>
        
        <div v-if="isLoggedIn" class="dropdown">
      <a class="dropdown-item" href="#" @click="handleLogout">Logout</a>
    </div>
      </ul>
    </div>
  </nav>
  
</template>
<style scoped>
.navbar .login-btn {
  position: absolute;
  top: 0px;
  right: 15px;
}
@media (max-width: 767px) {
  .navbar .login-btn {
    right: 75px;
  }
}
</style>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("clearToken");
      localStorage.removeItem("token"); // clear your user's token from localstorage
      localStorage.removeItem("user"); // clear your user from localstorage

      this.$router.push("/");
    },
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);

    setInterval(() => {
      this.handleLogout();
    }, 600000);
  },
};
</script>
