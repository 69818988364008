import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store/index.js'
import VueRouter from 'vue-router';

// import { BAlert } from 'bootstrap-vue'




// createApp(App).mount('#app')
const app = createApp(App)

import BaseCard from './components/layouts/BaseCard.vue';
import BaseButton from './components/layouts/BaseButton.vue';
import BaseBadge from './components/layouts/BaseBadge.vue';
import MainNavigation from './components/layouts/MainNavigation'
import BaseNavigation from './components/layouts/BaseNavigation'
import PartinerSection from './components/layouts/Partiners.vue'
// import CarouselSection from './components/layouts/carousel.vue'
import CarouselSection from './components/layouts/carousel.vue'


import NavBar from './components/layouts/NavBar.vue'
import Footer from './components/layouts/Footer.vue'

import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import moment from 'moment-timezone'
// import VueDatepicker from '@vuepic/vue-datepicker';


moment.tz.setDefault('Africa/Nairobi')

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'



app.use(store);
app.use(router);
app.use(VueRouter)
// app.use(BootstrapVue);
// app.use(IconsPlugin);
app.component('base-card',BaseCard);
app.component('base-button', BaseButton);
app.component('vue-sidebar', VueSidebarMenuAkahon)
app.component('base-badge',BaseBadge)
app.component('main-navigation', MainNavigation)
app.component('base-navigation', BaseNavigation)
app.component('partiner-section',PartinerSection)
app.component('carousel-section',CarouselSection)

app.component('nave-bar',NavBar);
app.component('footer-bar',Footer);
// app.component('vue-datepicker', VueDatepicker)
// app.component('b-alert', BAlert)
// app.use(VueMaterial)

app.mount('#app');

//new one
