<template>
    <div>
      
      <base-navigation></base-navigation>
      <base-card>
        <form @submit.prevent="registerClient">
  <div class="form-group">
    <div class="row">
      <div class="col">
        <div>
          <label for="name">ID Number:</label>
          <input type="text" v-model="formData.id_number" id="idnum" required />
        </div>
        <div>
          <label for="name">Names:</label>
          <input type="text" v-model="formData.name" id="name" required />
        </div>
        <div>
          <label for="registrationDate">Date of birth:</label>
          <input type="date" v-model="formData.dob" id="dob" required />
        </div>
        <div>
          <label for="name">Gender:</label>
          <select v-model="formData.gender" id="gender" required>
            <option disabled value="">Select  Gender</option>
            <option>Male</option>
            <option>Female</option>

          </select>
        </div>
        <!-- <div>
          <label for="country">Country:</label>
          <select v-model="formData.country" id="country" required>
            <option disabled value="">Please select one</option>
            <option v-for="country in countries" :key="country" :value="country">{{ country }}</option>
          </select>
          
        </div> -->

      </div>
      <div class="col">
        <div>
          <label for="name">Marital Status:</label>
          <select v-model="formData.mstatus" id="mstatus" required>
            <option disabled value="">Please select status</option>
            <option>Married</option>
            <option>Single</option>

          </select>
        </div>
        <div>
          <label for="name">Location:</label>
          <input type="text" v-model="formData.location" id="location" required />
        </div>
        <div>
          <label for="registrationDate">Business Type:</label>
          <input type="text" v-model="formData.btype" id="business_type" required />
        </div>
        <div>
          <label for="name">Business Name:</label>
          <input type="text" v-model="formData.bname" id="businessName" required />
        </div>
        <!-- <div>
          <label for="country">Country:</label>
          <select v-model="formData.country" id="country" required>
            <option disabled value="">Please select one</option>
            <option v-for="country in countries" :key="country" :value="country">{{ country }}</option>
          </select>
          
        </div> -->

      </div>
      <!-- <div class="col">
        <div>
          <label for="status">referee Name:</label>
          <input type="text" v-model="formData.refName" id="refName" required />

          
        </div>
        <div>
          <label for="status">referee Id Number:</label>
          <input type="text" v-model="formData.refId" id="refId" required />

          
        </div>
        <div>
          <label for="status">referee Phone:</label>
          <input type="text" v-model="formData.refPhone" id="refPhone" required />

          
        </div>

        <div>
          <label for="name">Relationship:</label>
          <select v-model="formData.refrelationship" id="refrelationship" required>
            <option disabled value="">Select referee relationship</option>
            <option>Father</option>
            <option>Mother</option>
            <option>Sibling</option>
            <option>Friend</option>
            <option>Child</option>



          </select>
        </div>
        
       

</div> -->

    </div>
   
        
  </div>
  
 
  <button type="submit" class="btn btn-primary">Submit</button>
</form>

</base-card>

   
    <div class="client-registration-form">
      <!-- <form @submit.prevent="submitForm">
        <div>
          <label for="name">Name:</label>
          <input type="text" v-model="form.name" id="name" required />
        </div>
      
        
      </form> -->
    </div>
</div>
  </template>
  
  <script>
import axios from 'axios';
  
  export default {
    data() {
      return {
        formData: {
          name: '',
          id_number: '',
          location:'',
          Registration_date: '',
          mstatus: '',
          gender:'',
          btype:'',
          bname:'',
         
         
        },
        // countries: ['USA', 'Canada', 'UK', 'Australia', 'India', 'Nigeria', 'South Africa']
      };
    },
    methods: {
      async registerClient() {
      const formIsValid =
        this.formData.name &&
        this.formData.id_number 
        
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/remmi/client/registration",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/clients");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.name) {
          this.errors.push("Enter client Name ");
        }
        if (!this.formData.refName) {
          this.errors.push("Enter refree name");
        }
        
        

        console.log("form is invalid");
      }
    },
      submitForm() {
        // Handle form submission, e.g., send data to an API
        console.log('Form data:', this.form);
        // Reset the form
        this.form.name = '';
        this.form.registrationDate = '';
        this.form.country = '';
      }
    }
  };
  </script>
  
  <style scoped>
  
  .client-registration-form {
    max-width: 400px;
    margin: auto;
  }
  form div {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input, select, button {
    width: 100%;
    padding: 0.5rem;
  }
  </style>
  